<template>
  <div class="blog-side-bar-view">
    <!-- <div class="cover blog-sidebar-review">
      <img
        :src="require('@/assets/images/testimonials/lover1.png')"
        alt="user"
      >

      <h3>Rosalina D. Willaimson</h3>
      <span>Traveller/Photographer</span>
      <p class="text-center">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis distinctio, odio, eligendi suscipit reprehenderit atque.
      </p>
    </div> -->

    <!-- search -->
    <div class="cover blog-sidebar-search" v-if="$route.path === '/blog'">
      <div class="blog-sidebar-title">
        Search Objects
      </div>
      <div class="search-view-item">
        <input
          v-model="search"
          placeholder="search your keyword..."
        >
        <div
          class="search-button"
          @click="searchPosts()"
        >
          Search
        </div>
      </div>
    </div>
    <!-- end search -->

    <!-- contact  -->
    <!-- <div class="cover blog-sidebar-contact">
                contact
              </div> -->
    <!-- contact view end -->
    <!-- latest blog -->
    <div
      v-if="posts.length"
      class="cover blog-sidebar-blog"
    >
      <div class="blog-sidebar-title">
        Latest Blog
      </div>

      <!-- blog content start -->
      <div
        v-for="(item, index) in posts"
        :key="index"
        class="blog-list-view"
        @click="showContent(item.slug)"
      >
        <img
          :src="getImage(item.image)"
          alt="user"
        >
        <div class="blog-list-view-content">
          <h5>{{ item.title | setTruncate(69,"...") }}</h5>
          <p class="text-view">
            <feather-icon
              class=""
              icon="CalendarIcon"
            /> <span>June 22, 2020</span>
          </p>
        </div>
      </div>
    </div>
    <!-- end latest blog -->
    <!-- blog category -->
    <div class="cover blog-sidebar-category">
      <div class="blog-sidebar-title">
        Top Category
      </div>
      <div class="category-view">
        <div
          v-for="(item, index) in categories"
          :key="index"
          class="category-item"
          :class="active(item.title)?'active-category':''"
          @click="showCategoryBlog(item)"
        >
          <span>{{ item.title }}</span><span>({{ item.posts_count }})</span>
        </div>
      </div>
    </div>
    <!-- blog category end -->

    <!-- follow us -->
    <!-- <div class="cover blog-sidebar-social">
                social
              </div> -->
    <!-- follow us box -->

    <!-- tags us -->
    <!-- <div class="cover blog-sidebar-tags">
                tags
              </div> -->
    <!-- tags us box -->
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      categories: [],
      search: null,
    }
  },
  computed: {
    categoryId() {
      return this.$store.getters['site/categoryId']
    },
    posts() {
      return this.$store.state.site.filter_post
    },
  },
  watch: {
    categoryId(id) {
      if (id) {
        this.getCategoryPosts(id)
      }
    },
  },
  mounted() {
    // this.getPosts()
    this.getCategoryPosts(this.$route.params?.title)
    this.getCategories()
  },
  methods: {
    searchPosts() {
      const query = `search=${this.search}`
      if (this.categoryId) {
        this.getCategoryPosts(this.categoryId, query)
      } else {
        this.$store.dispatch('site/allPosts', query)
      }
    },
    active(id) {
      return id.toLowerCase() === this.$store.getters['site/categoryId']
    },
    getCategoryPosts(categoryId, query = '') {
      if (categoryId) {
        this.$http.get(`/api/categories/${categoryId}/posts?${query}`).then(response => {
          this.$store.commit('site/setPosts', response.data.data)
        })
      }
    },
    getPosts() {
      const { id } = this.$route.params
      this.$store.dispatch('site/getFilterPost', id)
    },
    getImage(image) {
      return `${process.env.VUE_APP_STORAGE_PATH}/${image}`
    },
    showContent(title) {
      this.$router.push(`/blog/${title}`).catch(() => { })
      this.$store.dispatch('site/showSinglePost', title)
      this.getPosts()
    },
    showCategoryBlog(category) {
      this.$store.commit('site/setCategoryId', category.title)
      this.$router.push(`/blog-category/${category.title.toLowerCase()}`).catch(() => { })
    },
    date(data) {
      return moment(data).format('ddd DD-MMM-YY')
    },
    getCategories() {
      this.$http.get('/api/categories')
        .then(res => {
          this.categories = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve categories')
        })
    },
  },
}
</script>
