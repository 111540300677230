<template>
  <div>
    <navbar />

    <bread-creamb title="Blog" />
    <section class="blog-view">
      <div class="container">
        <div class="row">
          <div
            v-if="posts.length"
            class="col-md-8 col-lg-8"
          >
            <!-- blog content view start -->
            <div
              v-for="(item, index) in posts"
              :key="index"
              class="blog-content-view"
              @click="showContent(item.slug)"
            >
              <img
                :src="getImage(item.image)"
                alt="user"
                class="blog-image"
              >
              <div class="blog-view-body">
                <h2 class="mt-3 mb-3 blog-view-content-heading">
                  {{ item.title }}
                </h2>
                <div class="comment-view">
                  <div class="date-item">
                    <feather-icon
                      class="icon-view"
                      icon="MessageSquareIcon"
                    /> <span>{{ date(item.created_at) }}</span>
                  </div>
                  <div class="comment-item">
                    <feather-icon
                      class="icon-view"
                      icon="MessageSquareIcon"
                    /> <span>0 Comments</span>
                  </div>
                </div>

                <p class="mt-3 mb-4">
                  {{ item.description }}
                </p>
                <hr>
                <div class="author-view">
                  <b-avatar v-if="item.author.avatar === null || item.author.avatar" />
                  <b-avatar
                    v-else
                    :src="getAvatar(item.author.avatar)"
                  />
                  <span>{{ item.author.full_name }}, Author</span>
                </div>
              </div>
            </div>
            <!-- blog content view end -->
            <b-pagination
              v-model="currentPage"
              class="mt-3"
              :total-rows="rows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              :align="right"
              @input="paginateData"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
          <div
            v-else
            class="col-md-8 col-lg-8"
          >
            <h3 class="text-center">
              There are no posts in this category
            </h3>
          </div>
          <div class="col-md-4 col-lg-4">
            <blog-sidebar />
          </div>
        </div>
      </div>
    </section>

    <app-footer />
  </div>
</template>

<script>
import moment from 'moment'
import { BPagination, BAvatar } from 'bootstrap-vue'
import Navbar from './site-content/Navbar.vue'
import AppFooter from './site-content/AppFooter.vue'
import BreadCreamb from './site-pages/BreadCreamb.vue'
import BlogSidebar from './site-pages/BlogSidebar.vue'

export default {
  components: {
    Navbar,
    AppFooter,
    BreadCreamb,
    BlogSidebar,
    BPagination,
    BAvatar,
  },
  data() {
    return {
      right: 'right',
    }
  },
  computed: {
    currentPage() {
      return this.$store.getters['site/currentPage']
    },
    rows() {
      return this.$store.getters['site/totalRows']
    },
    perPage() {
      return this.$store.getters['site/perpage']
    },
    posts() {
      return this.$store.state.site.posts
    },
  },
  created() {
    this.$store.commit('site/setCategoryId', this.$route.params?.title)
    // this.getCategoryPosts();
  },
  methods: {
    getAvatar(image) {
      if (image === undefined || image === null) return null
      return `${process.env.VUE_APP_STORAGE_PATH}/${image}`
    },
    getImage(image) {
      return `${process.env.VUE_APP_STORAGE_PATH}/${image}`
    },
    showContent(title) {
      this.$router.push(`/blog/${title}`).catch(() => { })
    },
    date(data) {
      return moment(data).format('ddd DD-MMM-YY')
    },
    paginateData(page) {
      this.$store.dispatch('site/allPost', { paginate: true, page }).then(response => {
        // this.rows = response.data.total
        // this.currentPage = response.data.current_page
      })
    },
  },
}
</script>

<style>

</style>
